import React, { createContext, useState, useContext, useEffect } from "react"
import { getSettings, addOrUpdateSettings } from "lib/indexedb" // Removed unused deleteSettings import
import content from "./content.json"
import { useError } from "lib/Error/ErrorProvider" // Import the useError hook
import categories from "./settingsCategories.json"
import { isLoggedIn, getCurrentOrganizationId } from "lib/auth"
import ROLE_NAMES from "./roles.json"

// Create context
const SettingsContext = createContext()

// Custom hook to use the context
export const useSettings = () => useContext(SettingsContext)

// Provider component to wrap around the app
export const SettingsProvider = ({ children }) => {
    const loggedIn = isLoggedIn()
    const { triggerError } = useError()
    const [settings, setSettings] = useState(null)
    const [channelType, setChannelType] = useState("")
    const [options, setOptions] = useState([])
    const [disablePlayButton, setDisablePlayButton] = useState(false)

    const [settingsCategories, setSettingsCategories] = useState(
        categories?.settingsCategories
    )

    const setRoleSettings = async () => {
        const { roles } = getCurrentOrganizationId()
        const enabledRoles = roles?.edges?.filter(
            (role) =>
                role?.node?.name === ROLE_NAMES.INDEPENDENT_LIVING ||
                role?.node?.name === ROLE_NAMES.ASSISTED_LIVING
        )
        const setRoleSettingsPromises = enabledRoles?.map(async (role) => {
            return await addOrUpdateSettings({
                id: `vis-role-${role?.node?.name.toLowerCase()}`,
                roleId: role?.node?.id,
                type: "role",
                enabled: true,
                name: role?.name
            })
        })
        await Promise.all(setRoleSettingsPromises)
        return enabledRoles?.map((role) => {
            return {
                id: `vis-role-${role?.node?.name.toLowerCase()}`,
                roleId: role?.node?.id,
                type: "role",
                enabled: true,
                name: role?.name
            }
        })
    }

    const updateSettings = async (settings) => {
        const promises = settings.map((setting) =>
            addOrUpdateSettings({
                id: setting?.id,
                channelName: setting?.channelName,
                enabled: setting?.enabled,
                type: setting?.type
            })
        )
        await Promise.all(promises)
    }

    const fetchSettings = async () => {
        try {
            const alldBSettings = await getSettings()
            const visSettings = alldBSettings?.filter(
                (setting) => setting.id !== "community-configured"
            )

            if (!visSettings.length) {
                await updateSettings(content)
                const roles = await setRoleSettings()
                setSettings([...content, ...roles])
                return
            }

            const multiChannelSettings = visSettings.find(
                (setting) => setting?.type === "multiChannel"
            )
            const singleChannelSettings = visSettings.find(
                (setting) => setting?.type === "singleChannel"
            )

            if (!multiChannelSettings || !singleChannelSettings) {
                await updateSettings(content)
                const roles = await setRoleSettings()
                setSettings([...content, ...roles])
            } else if (visSettings.length <= 5) {
                const roles = await setRoleSettings()
                setSettings([...visSettings, ...roles])
            } else {
                setSettings(visSettings)
            }
        } catch (error) {
            triggerError(
                `Error in setting vis settings: ${error.message}`,
                error
            )
        }
    }

    useEffect(() => {
        if (loggedIn) {
            fetchSettings()
        }
    }, [loggedIn])

    useEffect(() => {
        if (settings) {
            if (settings.length) {
                if (channelType !== "") {
                    const filteredOptions = settings
                        ? settings.filter(
                              (option) =>
                                  option?.type?.toLowerCase() ===
                                  channelType?.toLowerCase()
                          )
                        : []

                    if (channelType === "multiChannel") {
                        setOptions([
                            filteredOptions.find(
                                (option) => option?.channelName === "Messages"
                            ),
                            filteredOptions.find(
                                (option) => option?.channelName === "Meals"
                            ),
                            filteredOptions.find(
                                (option) => option?.channelName === "Events"
                            )
                        ])
                        let disabledOptions = 0
                        filteredOptions.forEach((option) => {
                            if (!option?.enabled) {
                                disabledOptions += 1
                            }
                        })
                        if (disabledOptions === 3) {
                            setDisablePlayButton(true)
                        } else {
                            setDisablePlayButton(false)
                        }
                    } else {
                        setOptions(filteredOptions)
                        setDisablePlayButton(false)
                    }
                } else {
                    let disabledOptions = 0
                    settings.forEach((option) => {
                        if (
                            !option?.enabled &&
                            option?.type === "multiChannel"
                        ) {
                            disabledOptions += 1
                        }
                    })
                    if (disabledOptions === 3) {
                        setChannelType("singleChannel")
                    } else {
                        setChannelType("multiChannel")
                    }
                }
            }
        }
    }, [settings, channelType])

    return (
        <SettingsContext.Provider
            value={{
                channelType,
                setChannelType,
                options,
                setOptions,
                settings,
                setSettings,
                disablePlayButton,
                settingsCategories,
                setSettingsCategories
            }}
        >
            {children}
        </SettingsContext.Provider>
    )
}
