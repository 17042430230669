import React, { createContext, useState, useEffect } from "react"
import { DEFAULT_FEATURE_FLAG_STATE } from "./Constants"
export const FeatureFlagsContext = createContext({})

const FeatureFlagsProvider = ({ children }) => {
    const [flags, setFlags] = useState({ ...DEFAULT_FEATURE_FLAG_STATE })

    useEffect(() => {
        const newFlagState = { ...DEFAULT_FEATURE_FLAG_STATE }
        setFlags({ ...newFlagState })
    }, [])

    return (
        <FeatureFlagsContext.Provider
            value={{
                featureFlags: flags
            }}
        >
            {children}
        </FeatureFlagsContext.Provider>
    )
}

export default FeatureFlagsProvider
