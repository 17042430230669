import { openDB } from "idb"
import { getCurrentOrganizationId, isAutoLoggedIn } from "./auth"

const COMMUNITY_CONFIGURATION_ID = "community-configured"

export const initDB = async () => {
    const { slug, community } = getCurrentOrganizationId()

    const autoLoggedIn = isAutoLoggedIn()

    const dbName = community
        ? `Wellzesta-TV-Settings-${slug}-community${autoLoggedIn ? "-autoLogin" : ""}`
        : `Wellzesta-TV-Settings-${slug}-username${autoLoggedIn ? "-autoLogin" : ""}`

    return openDB(dbName, 1, {
        upgrade(db) {
            if (!db.objectStoreNames.contains("settings")) {
                db.createObjectStore("settings", { keyPath: "id" })
            }
        }
    })
}

export const setCommunityConfiguration = async () => {
    const db = await initDB()
    const tx = db.transaction("settings", "readwrite")
    const store = tx.store
    store.add({ id: COMMUNITY_CONFIGURATION_ID, configured: true })
    await tx.done
}

export const getCommunityConfiguration = async () => {
    const db = await initDB()
    const tx = db.transaction("settings", "readwrite")
    const store = tx.store
    const configuration = await store.get(COMMUNITY_CONFIGURATION_ID)
    if (configuration) {
        return configuration
    }
    return false
}

// Check if the setting exists, then add or update
export const addOrUpdateSettings = async (item) => {
    const db = await initDB()
    const tx = db.transaction("settings", "readwrite")
    const store = tx.store

    // Check if the item exists by the id
    const existingItem = await store.get(item.id)

    if (existingItem) {
        // If the item exists, update it
        const updatedData = { ...existingItem, ...item }
        store.put(updatedData)
    } else {
        // If the item doesn't exist, add it
        store.add(item)
    }
    await tx.done
}

// Get all settings
export const getSettings = async () => {
    const db = await initDB()
    return db.getAll("settings")
}

// Delete setting by ID
export const deleteSettings = async (id) => {
    const db = await initDB()
    const tx = db.transaction("settings", "readwrite")
    tx.store.delete(id)
    await tx.done
}
