import React, { useEffect, useState } from "react"
import Head from "next/head"
import theme from "lib/theme"
import { ThemeProvider } from "reakit"
import { RouteGuard } from "components/RouteGuard"
import FeatureFlagsProvider from "lib/FeatureFlags/FeatureFlagsProvider"
import ErrorProvider from "lib/Error/ErrorProvider"
import { SettingsProvider } from "lib/Settings/SettingsProvider"
import Rollbar from "rollbar"
import { clientConfig } from "lib/rollbar"
import { ApolloClientProvider } from "lib/ApolloClient/ApolloClientProvider"
import "animate.css"

const VisualizeApp = ({ Component, pageProps }) => {
    const [rollbar, setRollbar] = useState(null)

    useEffect(() => {
        if (window !== "undefined") {
            const rollbar = new Rollbar(clientConfig)
            setRollbar(rollbar)
        }
    }, [])

    useEffect(() => {
        if (rollbar) {
            if (typeof window !== "undefined") {
                window.onerror = (message, source, lineno, colno, error) => {
                    rollbar.error(error || message)
                }
                window.addEventListener("unhandledrejection", (event) => {
                    rollbar.error(event.reason)
                })
            }
        }
    }, [rollbar])

    if (rollbar) {
        return (
            <>
                <ErrorProvider rollbar={rollbar}>
                    <SettingsProvider>
                        <Head>
                            <meta
                                name="viewport"
                                content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
                            />
                            <title>Wellzesta Visualize</title>
                        </Head>
                        <ThemeProvider theme={theme}>
                            <FeatureFlagsProvider>
                                <RouteGuard>
                                    <ApolloClientProvider>
                                        <Component {...pageProps} />
                                    </ApolloClientProvider>
                                </RouteGuard>
                            </FeatureFlagsProvider>
                        </ThemeProvider>
                    </SettingsProvider>
                </ErrorProvider>
            </>
        )
    }

    return null
}

export default VisualizeApp
