import React, { createContext, useContext, useEffect, useState } from "react"
import createApolloClient from "lib/apolloClient"
import { getCurrentOrganizationId, cookies, isLoggedIn } from "lib/auth"
import { useRouter } from "next/router"

const ApolloClientContext = createContext()

export const ApolloClientProvider = ({ children }) => {
    const router = useRouter()
    const [apolloClient, setApolloClient] = useState(null)
    const loggedIn = isLoggedIn()
    const { token } = router.query

    const initializeApolloClient = async () => {
        const orgData = await getCurrentOrganizationId()
        if (orgData) {
            const { autoLoggedIn } = orgData
            if (autoLoggedIn) {
                if (token) {
                    // Auto Login (non incognito mode)
                    setApolloClient(createApolloClient(token))
                } else {
                    // In case they refresh the site while in incognito mode and used auto login
                    const storedToken = window.localStorage.getItem("TOKEN")
                    setApolloClient(createApolloClient(storedToken))
                }
            } else {
                // Normal Login
                const storedToken = cookies.get("TOKEN")
                setApolloClient(createApolloClient(storedToken))
            }
        }

        // Loading Incognito Mode and with Auto Login URL (first time)
        if (!orgData && token) {
            setApolloClient(createApolloClient(token))
        }
    }

    useEffect(() => {
        if (router) {
            if (!apolloClient) {
                initializeApolloClient()
            }
        }
    }, [router, loggedIn])

    return (
        <ApolloClientContext.Provider value={{ apolloClient, setApolloClient }}>
            {children}
        </ApolloClientContext.Provider>
    )
}

export const useApolloClient = () => useContext(ApolloClientContext)
