import React, { createContext, useContext, useState } from "react"
const ErrorContext = createContext()

export const useError = () => useContext(ErrorContext)

const ErrorProvider = ({ children, rollbar }) => {
    const [hasError, setHasError] = useState(false)
    const triggerError = (message, error, orgName) => {
        setHasError(true)
        rollbar.error(error, { message, orgName })
    }

    const resetError = () => {
        setHasError(false)
    }

    if (hasError) {
        return (
            <div
                style={{
                    textAlign: "center",
                    height: "100vh",
                    width: "100vw",
                    backgroundColor: "black",
                    color: "white",
                    display: "flex",
                    flexDirection: "column"
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        height: "100vh"
                    }}
                >
                    <h1>We are very sorry! Something went wrong.</h1>
                </div>
            </div>
        )
    }

    return (
        <ErrorContext.Provider value={{ triggerError, resetError }}>
            {children}
        </ErrorContext.Provider>
    )
}

export default ErrorProvider
