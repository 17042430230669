import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { TOKEN, cookies } from "./auth"

// Define possible types
const possibleTypes = {
    DashboardMessage: ["Alert", "Announcement", "Happening", "Message"]
}

const httpLink = createHttpLink({
    uri: process.env.NEXT_PUBLIC_GRAPHQL_API_URL
})

// Set the context for authorization headers
const createApolloClient = (initialToken) => {
    if (initialToken) {
        const authLink = setContext((_, { headers }) => {
            const token = initialToken || cookies.get(TOKEN)
            return {
                headers: {
                    ...headers,
                    authorization: token
                }
            }
        })

        // Return a new Apollo Client instance
        return new ApolloClient({
            link: authLink.concat(httpLink),
            cache: new InMemoryCache({
                possibleTypes
            })
        })
    }

    return null
}

export default createApolloClient
