import { injectGlobal, css } from "reakit"

injectGlobal`
* {
  box-sizing: border-box;
}
html {
  font-size: 1.5vh;
}
body {
  font-family: "Inter", sans-serif;
  margin: 0;
  background-color: rgba(0, 0, 0, 0) !important;
}
`

const Avatar = css`
    border-radius: 50%;
    width: 8.75rem;
    height: 8.75rem;
    overflow: hidden;
    object-fit: cover;
`

const theme = {
    palette: {
        primary: ["#0F766E", "#2DD4BF"], // green
        primaryText: ["white", "white"],
        secondary: ["#092b2b", "#134E4A", "#50625d"], // gray
        secondaryText: ["white", "white", "white"],
        background: ["#f5f6f0"],
        backgroundText: ["black"]
    },
    Avatar
}

export default theme
