const baseConfig = {
    captureUncaught: true,
    captureUnhandledRejections: true,
    environment: process.env.NEXT_PUBLIC_ENV || "production"
}

export const clientConfig = {
    accessToken: process.env.NEXT_PUBLIC_ROLLBAR_CLIENT_TOKEN,
    ...baseConfig
}
