import Cookies from "universal-cookie"

const TOKEN = "token"

const cookies = new Cookies()

const setCurrentOrganization = (id, logoUrl, name, slug) => {
    if (window) {
        window.localStorage.setItem(
            "organization",
            JSON.stringify({ id, logoUrl, name, slug })
        )
    }
}

const getCurrentOrganization = () => {
    try {
        if (typeof window !== "undefined") {
            return JSON.parse(window.localStorage.getItem("organization"))
        }
    } catch {
        return null
    }
}

const getCurrentOrganizationId = () => {
    try {
        if (window) {
            const { id } = JSON.parse(
                window.localStorage.getItem("organization")
            )
            return id
        }
    } catch {
        return null
    }
}

const isLoggedIn = () => {
    try {
        if (getCurrentOrganizationId()) {
            return true
        }
    } catch (err) {
        console.error(err)
        return false
    }
}

const isAutoLoggedIn = () => {
    try {
        if (getCurrentOrganizationId()) {
            const { autoLoggedIn } = getCurrentOrganizationId()
            return autoLoggedIn
        }
    } catch (err) {
        console.error(err)
        return false
    }
}

const setToken = (token, autoLogin) => {
    if (autoLogin) {
        return window.localStorage.setItem("TOKEN", token)
    }
    cookies.set("TOKEN", token, {
        path: "/",
        expires: new Date(Date.now() + 10 * 365 * 24 * 60 * 60 * 1000)
    })
}

const logout = () => {
    try {
        cookies.remove(TOKEN, { path: "/" })
        if (window) {
            window.localStorage.removeItem("organization")
        }
        window.location.href = "/"
    } catch (error) {
        console.error("An error occurred during logout: ", error)
    }
}

export {
    setCurrentOrganization,
    getCurrentOrganization,
    getCurrentOrganizationId,
    isLoggedIn,
    isAutoLoggedIn,
    setToken,
    TOKEN,
    cookies,
    logout
}
